import { Button } from "../components/Button/Button"
import { Input } from "../components/Input/index.jsx"
import { ReactComponent as Logo } from "../assets/main_logo.svg"
import { t } from "i18next"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { userChangePassword } from "../features/auth/authActions.js"
import { PasswordValidator } from "../components/PasswordValidator/PasswordValidator.jsx"

export function RedefinePasswordPage() {
    const navigate = useNavigate()
    const [password, setPassword] = useState("")

    const [validMessage, setValidationMessage] = useState(null)
    const [isValid, setIsValid] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(false)

    const { error, success } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const changePassword = (e) => {
        e.preventDefault()
        const oldPassword = e.target.elements.oldPassword.value
        const newPassword = e.target.elements.newPassword.value
        const newPasswordConfirmation = e.target.elements.newPasswordConfirmation.value

        if (!isPasswordValid) {
            return
        }

        if (oldPassword === newPassword) {
            setIsValid(false)
            setValidationMessage(t("newPasswordSameAsOld"))
            return
        }

        if (newPassword !== newPasswordConfirmation) {
            setIsValid(false)
            setValidationMessage(t("passwordsDoNotMatch"))
            return
        }

        dispatch(userChangePassword({ oldPassword, newPassword, newPasswordConfirmation }))
    }

    useEffect(() => {
        if (success.changePassword) {
            navigate("/dashboard")
        }

        if (error?.status === 401) {
            setIsValid(false)
            setValidationMessage(t("unauthorizedError"))
        }

        if (error?.data.message === "Senha inválida!") {
            setIsValid(false)
            setValidationMessage(t("invalidPassword"))
        }
    }, [navigate, success, error])

    return (
        <div className="flex flex-col lg:flex-row h-screen w-screen overflow-hidden overflow-y-auto">
            <div className="flex flex-col w-full justify-center items-center p-8 lg:p-0">
                <Logo className="w-32 lg:w-56 h-auto pb-10 lg:pb-20" />
                <form className="flex flex-col space-y-6 w-1/2 max-w-md" onSubmit={changePassword}>
                    <h1 className="self-start font-poppins font-semibold text-3xl pb-6">{t("redefinePassword")}</h1>

                    <Input.Root>
                        <Input.Content id="oldPassword" type="password" onChange={() => { setIsValid(true); setValidationMessage(null) }} placeholder={t("oldPassword")} />
                    </Input.Root>
                    <Input.Root>
                        <Input.Content id="newPassword" value={password} type="password" onChange={(e) => { setPassword(e.target.value); setIsValid(true); setValidationMessage(null) }} placeholder={t("newPassword")} />
                    </Input.Root>
                    <PasswordValidator password={password} onValidationChange={setIsPasswordValid} />
                    <Input.Root>
                        <Input.Content id="newPasswordConfirmation" type="password" onChange={() => { setIsValid(true); setValidationMessage(null) }} placeholder={t("repeatPassword")} />
                    </Input.Root>
                    {validMessage && <div className="self-center text-red-500 text-base">{validMessage}</div>}

                    <div className="self-center">
                        <Button
                            type="submit"
                            children={t("save")}
                            className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                            disabled={!isValid}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
